import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class SuggestionService {
    constructor(private http: HttpClient) {}

    getSuggestion = (file: any, entity: any, type: any, objectImage: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/ai-info/${entity}/${type}/${objectImage}`, file).toPromise();
    };

    getSuggestionText = (text: any, entity: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/ai-info/text/${entity}`, text).toPromise();
    };

    getCommand = (file: any, commands: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/command-voice/${commands}`, file).toPromise();
    };

    getCommandText = (text: any, commands: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/command-text/${commands}`, text).toPromise();
    };

    getDbData = (prompt): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/gemini-sql/${prompt}`).toPromise();
    };
}
