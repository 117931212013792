import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {SuggestionService} from 'app/blocks/service/api/suggestion.service';
import {EntityAction} from 'app/blocks/model/action.model';
import {error} from 'console';

enum InputType {
    DOCUMENT = 'DOCUMENT',
    VOICE = 'VOICE',
    IMAGE = 'IMAGE',
    TEXT = 'TEXT'
}

@Injectable({
    providedIn: 'root'
})
export class AIInputService {
    public entityCommands = {};
    private suggestionData: any = new BehaviorSubject<any>(null);
    private resource: any;

    constructor(private suggestionService: SuggestionService) {}

    public setData(resource: any, actions: EntityAction[], onSuggestion: any): void {
        this.resource = resource;

        this.entityCommands['CREATE_' + this.resource.name.toUpperCase()] = () => {};
        for (const action of actions) {
            if (action.isAIAction !== false) {
                this.entityCommands[action.label] = action.action;
            }
        }

        this.suggestionData.subscribe((data) => {
            if (data) {
                onSuggestion(data);
            }
        });
    }

    private async processNewEntityAction(prompt: any, isText = true): Promise<void> {
        let data = null;
        if (isText) {
            data = await this.suggestionService.getSuggestionText(prompt, this.resource.name);
        } else {
            data = await this.suggestionService.getSuggestion(prompt, this.resource.name, InputType.VOICE, false);
        }
        if (data?.suggestion) {
            this.resource.navigate.toNew({});
            this.suggestionData.next(data);
        }
        return data?.errors;
    }

    public async processCommands(prompt: any, commands: any[], isText = true): Promise<void> {
        let errors = null;
        const commandStr = commands.join(' ');

        if (commandStr.includes('CREATE_' + this.resource.name.toUpperCase())) {
            if (commands.length === 1) {
                errors = await this.processNewEntityAction(prompt, isText);
            }
            return errors;
        }

        if (commandStr == '') {
            errors = [
                {
                    entity: this.splitAndCapitalize(this.resource.name),
                    error: 'Command Not Recognized',
                    suggestion: 'Try Again'
                }
            ];
            return errors;
        }

        for (const command of commands) {
            this.entityCommands[command]();
        }

        return errors;
    }

    splitAndCapitalize(input: string): string {
        return input
            .split(/(?=[A-Z])/)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
}
